
import { mapGetters, mapActions } from "vuex";
export default {
    name: "RoleController",
    data() {
        return {
            defaultProps: {
                children: "has_children",
                label: "name",
            },
            loadData: false,
        };
    },
    computed: {
        ...mapGetters({
            rules: "roles/rules",
            model: "roles/model",
            columns: "roles/columns",
            permissions: "roles/permissions",
        }),
    },
    methods: {
        ...mapActions({
            fetchData: "roles/create",
        }),

        getPermissions() {
        if (!this.loadData) {
                this.fetchData()
                .then((res) => {})
                .catch((err) => {
                    this.$alert(err);
                });
            }
        },
        submit(close = true) {
            this.$refs["form"].validate((valid) => {
                if (valid) {
                    let permissions = [];
                    let checkedPermissions = this.$refs.permissions.getCheckedNodes();
                    for (let key in checkedPermissions) {
                        if (checkedPermissions.hasOwnProperty(key)) {
                        let checkedPermission = checkedPermissions[key];
                        permissions.push(checkedPermission.id);
                        }
                    }

                    this.form["permissions"] = permissions.filter(function (e) {
                        return e;
                    });
                    this.loadingButton = true;
                    this.save(this.form)
                        .then((res) => {
                            this.loadingButton = false;
                            this.$alert(res);
                            this.parent().listChanged();
                            if (close) this.close();
                        })
                        .catch((err) => {
                            this.loadingButton = false;
                            this.$alert(err);
                        });
                }
            });
        },
    }
}