<template >
    <div>
        <div class="modal-header">
            <div class="modal-title-my">
                {{
                    $t("message.new_c", {
                        m: $t("message.roles"),
                    })
                }}
            </div>
            <div>
                <crm-store-update-close
                    :button_type="'store'"
                    :loading="loadingButton"
                    @c-submit="submit(true)"
                    @c-close="close()"
                ></crm-store-update-close>
            </div>
        </div>

        <div class="bodal-body-my">
            <el-form ref="form" status-icon :model="form" :rules="rules">
                <el-row :gutter="20">
                    <el-col :span="24">
                        <el-form-item label="Dostup berish">
                            <el-radio-group v-model="form.status">
                                <el-radio :label="true">Barchasini ko'rish</el-radio>
                                <el-radio :label="false">O'zinikini ko'rish</el-radio>
                            </el-radio-group>
                        </el-form-item>
                    </el-col>
                    <el-col :span="24">
                        <el-form-item
                            :label="$t('message.roles')"
                            prop="name"
                            class="label_mini"
                        >
                            <crm-input
                                :size="'medium'"
                                :inputValue="form.name"
                                v-model="form.name"
                                :placeholder="$t('message.roles')"
                            ></crm-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="24">
                        <el-form-item
                            :label="$t('message.type_fullname')"
                            class="label_mini"
                        >
                            <crm-input
                                :size="'medium'"
                                :inputValue="form.slug"
                                v-model="form.slug"
                                :placeholder="$t('message.type_fullname')"
                            ></crm-input>
                        </el-form-item>
                    </el-col>
                    <!-- end el-col -->
                    <el-col :span="24" >
            <div class="app-form__group mb-4 width-auto">
            <el-tree
                :data="[
                {
                    name: 'Разрешения',
                    has_children: permissions.permissions,
                },
                ]"
                show-checkbox
                node-key="id"
                :props="defaultProps"
                ref="permissions"
            >
            </el-tree>
            </div>
                    </el-col>
                </el-row>
            </el-form>
        </div>
        <!-- end app-modal__body -->
    </div>
</template>
<script>
import { mapGetters, mapActions } from "vuex";
import role from '@/utils/mixins/models/role';
import drawer from "@/utils/mixins/drawer";
import form from "@/utils/mixins/form";
export default {
    mixins: [form, drawer, role],
    computed: {
        ...mapGetters({
           
        }),
    },
    methods: {
        ...mapActions({
            save: "roles/store",
        }),
        afterOpen() {
            this.getPermissions();
        },
    },
};
</script>
